<template>
  <!-- modal -->
  <modal-google-map
    :isShow="isShow"
    :location="location"
    @save="saveMap"
    @close="isShow = false"
  />

  <!-- field -->
  <div class="mb-4">
    <div class="flex justify-between items-center mb-2">
      <label
        :for="id"
        class="block text-16 font-normal text-mytheme-grayscale900"
      >
        {{ label }}
      </label>
      <c-switch
        :id="makeIdSwtichGoogleMap"
        :value="location.active"
        @onChange="onChangeSwitch"
        labelOn="เปิดใช้งาน"
        labelOff="ปิดใช้งาน"
      />
    </div>
    <div class="relative">
      <img
        src="../../assets/img/check-location-icon.png"
        width="18"
        class="absolute top-1/2 left-3 transform -translate-y-1/2"
      >
      <input
        :value="location.active ? location.address : ''"
        :id="id"
        type="text"
        disabled
        :placeholder="placeholder"
        class="shadow border rounded-lg w-full py-3 pl-10 pr-28 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      >
      <button
        class="text-16 font-semibold absolute top-1/2 right-6 transform -translate-y-1/2"
        :disabled="!location.active"
        :class="location.active ? 'text-mytheme-grayscale900 cursor-pointer' : 'text-mytheme-grayscale300 cursor-not-allowed'"
        @click="isShow = true"
      >
        ค้นหาที่ตั้ง
      </button>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { makeId } from '../../utility/function/makeId'

import ModalGoogleMap from '../Modal/ModalGoogleMap.vue'
import CSwitch from '../Switch/CSwitch.vue'

export default {
  components: {
    ModalGoogleMap,
    CSwitch
  },
  props: {
    location: {
      type: Object
    },
    id: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: 'Google Map'
    },
    placeholder: {
      type: String,
      default: 'Placeholder'
    },
  },
  emits: ['onChangeSwitch', 'saveMap'],
  setup (props, { emit }) {

    const makeIdSwtichGoogleMap = computed(() => {
      return `googleMap${makeId(5)}`
    })

    const onChangeSwitch = (checked) => {
      emit('onChangeSwitch', checked)
    }

    const saveMap = (location) => {
      props.location.address = location.address
      emit('saveMap', location)
    }

    const { isShow } = useModal()

    return {
      makeIdSwtichGoogleMap,
      onChangeSwitch,
      saveMap,
      isShow
    }
  }
}

const useModal = () => {
  const isShow = ref(false)

  return {
    isShow
  }
}
</script>

<style>

</style>