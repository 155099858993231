<template>
  <div class="pb-6">
    <!-- รอบจัดงาน -->
    <div>
      <div class="flex flex-col xl:flex-row">
        <p class="text-16 font-normal w-24">รอบที่จัดงาน</p>

        <div class="flex-1 flex mt-2 xl:mt-0 xl:ml-3 space-x-6 items-center">
          <div
            v-for="(radio, index) in modelRadioTab"
            :key="index"
            class="flex items-center space-x-1"
          >
            <input
              v-model="mode"
              :id="radio.value"
              :value="radio.value"
              @input="submitSelectRadioTab(radio.value)"
              type="radio"
              class="form-radio h-5 w-5 bg-mytheme-blue"
            >

            <label :for="radio.value" class="text-16 font-normal">{{ radio.label }}</label>
          </div>
        </div>
      </div>

      <div class="mt-10">
        <div v-if="mode == 'morning'" class="relative border border-mytheme-grayscale300 p-3 pt-6 rounded-lg shadow">
          <span style="top: -14px;" class="absolute text-16 bg-white px-1">งานเช้า</span>
          <detail-wedding-form :data="morningForm" @onChange="handleInput"/>
        </div>

        <div v-if="mode == 'evening'" class="relative border border-mytheme-grayscale300 p-3 pt-6 rounded-lg shadow">
          <span style="top: -14px;" class="absolute text-16 bg-white px-1">งานเย็น</span>
          <detail-wedding-form :data="eveningForm" @onChange="handleInput"/>
        </div>

        <div v-if="mode == 'morningAndEvening'" class="flex space-x-1">
          <div class="relative w-1/2 border border-mytheme-grayscale300 p-3 pt-6 rounded-lg shadow">
            <span style="top: -14px;" class="absolute text-16 bg-white px-1">งานเช้า</span>
            <detail-wedding-form :data="morningForm" @onChange="handleInput"/>
          </div>

          <div class="relative w-1/2 border border-mytheme-grayscale300 p-3 pt-6 rounded-lg shadow">
            <span style="top: -14px;" class="absolute text-16 bg-white px-1">งานเย็น</span>
            <detail-wedding-form :data="eveningForm" @onChange="handleInput"/>
          </div>
        </div>
      </div>

      <!-- <tabs @selectTab="submitSelectMomentWedding($event.val)" :defaultTabAt="defaultTabAt">
        <tab title="งานเช้า" val="morning">
          <div class="relative border border-mytheme-grayscale300 p-3 pt-6 rounded-lg shadow">
            <span style="top: -14px;" class="absolute text-16 bg-white px-1">งานเช้า</span>
            <detail-wedding-form :data="morningForm" @onChange="handleInput"/>
          </div>
        </tab>

        <tab title="งานเย็น" val="evening">
          <div class="relative border border-mytheme-grayscale300 p-3 pt-6 rounded-lg shadow">
            <span style="top: -14px;" class="absolute text-16 bg-white px-1">งานเย็น</span>
            <detail-wedding-form :data="eveningForm" @onChange="handleInput"/>
          </div>
        </tab>

        <tab title="งานเช้าและเย็น" val="morningAndEvening">
          <div class="flex space-x-1">
            // column morning
            <div class="relative w-1/2 border border-mytheme-grayscale300 p-3 pt-6 rounded-lg shadow">
              <span style="top: -14px;" class="absolute text-16 bg-white px-1">งานเช้า</span>
              <detail-wedding-form :data="morningForm" @onChange="handleInput"/>
            </div>

            // column evening
            <div class="relative w-1/2 border border-mytheme-grayscale300 p-3 pt-6 rounded-lg shadow">
              <span style="top: -14px;" class="absolute text-16 bg-white px-1">งานเย็น</span>
              <detail-wedding-form :data="eveningForm" @onChange="handleInput"/>
            </div>
          </div>
        </tab>
      </tabs> -->
    </div>

    <div class="mt-9 text-right">
      <c-button color="primary" rounded="rounded-full" @onClick="submitSave">
        บันทึก
      </c-button>
    </div>
  </div>
</template>

<script>
import { computed, onBeforeMount, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'

import CButton from '../Button/Cbutton.vue'
import DetailWeddingForm from '../Form/DetailWedding.vue'

import Tabs from '../Tab/Tabs.vue'
import Tab from '../Tab/Tab.vue'

export default {
  components: {
    CButton,
    DetailWeddingForm,
    Tabs,
    Tab
  },
  setup () {
    const store = useStore()

    const { modelRadioTab, mode, submitSelectRadioTab } = useRadioSelect(store)
    // const { defaultTabAt, submitSelectMomentWedding } = useTabs(store)

    const {
      morningForm,
      eveningForm,
      handleInput,
      submitSave
    } = useFormDetailWedding(store)

    return {
      /* // tabs
      defaultTabAt,
      submitSelectMomentWedding, */

      // radioTab
      modelRadioTab,
      mode,
      submitSelectRadioTab,

      // form detail
      morningForm,
      eveningForm,
      handleInput,
      submitSave,
    }
  }
}

const useTabs = (store) => {
  const defaultTabAt = computed(() => {
    const { mode } = store.state.signupForm.eventInfo
    if(mode == 'morning') return 0
    if(mode == 'evening') return 1
    if(mode == 'morningAndEvening') return 2
  })

  const submitSelectMomentWedding = (val) => {
    store.commit('signupForm/setModeEventInfo', { val })
  }

  return {
    defaultTabAt,
    submitSelectMomentWedding
  }
}

const useRadioSelect = (store) => {
  const state = reactive({
    modelRadioTab: [
      { label: 'งานเช้า', value: 'morning' },
      { label: 'งานเย็น', value: 'evening' },
      { label: 'งานเช้าและเย็น', value: 'morningAndEvening' }
    ],
    mode: ''
  })

  onBeforeMount(() => {
    state.mode = store.state.signupForm.eventInfo.mode
  })

  const submitSelectRadioTab = (val) => {
    store.commit('signupForm/setModeEventInfo', { val })
  }

  return {
    ...toRefs(state),
    submitSelectRadioTab
  }
}

const useFormDetailWedding = (store) => {
  const morningForm = computed(() => store.state.signupForm.eventInfo.morning)
  const eveningForm = computed(() => store.state.signupForm.eventInfo.evening)

  const handleInput = (data) => {
    store.commit('signupForm/setParentState', { data })
  }

  const submitSave = async () => {
    try {
      store.commit('appState/TOGGLE_IS_LOADING')
      await store.dispatch('signupForm/save')
      store.dispatch('appState/showToast', {
        message: 'บันทึกสำเร็จ',
        delay: 1000 * 1.5
      })
    } catch (error) {
      console.error(error)
    } finally {
      store.commit('appState/TOGGLE_IS_LOADING')
    }
  }

  return {
    morningForm,
    eveningForm,
    handleInput,
    submitSave
  }
}
</script>

<style>

</style>