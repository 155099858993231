<template>
  <div class="bg-white p-7 mb-16">
    <div style="font-family: dmsans;">
      <p class="text-21 font-bold text-mytheme-grayscale900">
        {{ groomName }} & {{ brideName }}
      </p>
      <p class="text-16 font-normal text-mytheme-grayscale900">Wedding</p>
    </div>

    <carousel :items-to-show="1" class="mt-6">
      <slide v-for="(img, index) in images" :key="index">
        <frame-image :image="img" :width="320" :height="480" :label="`รูปภาพ พรีเวดดิ้ง${index + 1}`" />
      </slide>

      <template #addons>
        <navigation />
        <pagination />
      </template>
    </carousel>

    <div class="text-center mt-6">
      <button style="font-family: anuphan;" class="font-bold w-full rounded-lg shadow w-72 py-3 bg-mytheme-blue text-white rounded-full text-16">
        ลงทะเบียน
      </button>
    </div>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import FrameImage from '../../FrameImage/Image.vue'

export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    FrameImage
  },
  props: {
    groomName: {
      type: String
    },
    brideName: {
      type: String
    },
    images: {
      type: Array
    }
  },
  setup () {
    const settings = {
      "dots": true,
      "dotsClass": "slick-dots custom-dot-class",
      "edgeFriction": 0.35,
      "infinite": false,
      "speed": 500,
      "slidesToShow": 1,
      "slidesToScroll": 1
    }

    return {
      settings
    }
  }
}
</script>

<style>

</style>