<template>
  <div class="pb-6">
    <!-- alert -->
    <div class="rounded-md bg-mytheme-blue p-4">
      <p class="text-16 font-normal text-white mb-0">
        ในกรณี แขกไม่สามารถมาร่วมงาน จะสามารถเลือกโอนเงินใส่ซองมาให้เจ้าบ่าวเจ้าสาวได้
      </p>
      <p class="text-16 font-normal text-mytheme-grayscale50 mb-0">
        การโอนจะเป็นการโอนโดยตรงไปที่ <span class="font-bold" style="color: #a7ff00;">บัญชีของเจ้าป่าวเจ้าสาว</span> (ไม่ผ่านคนกลาง)
      </p>
    </div>

    <div class="flex justify-between items-start mt-8">
      <p class="text-17 text-mytheme-grayscale900">พร้อมเพย์</p>

      <c-switch
        id="activePromptpay"
        :value="promptpay.active"
        @onChange="handleInput('active', $event)"
        labelOn="เปิดใช้งาน"
        labelOff="ปิดใช้งาน"
      />
    </div>

    <!-- full name -->
    <c-field
      id="fullName"
      :value="promptpay.namePromptpay"
      :disabled="!promptpay.active"
      @onChange="handleInput('namePromptpay', $event)"
      label="ชื่อ - นามสกุล (ไม่ต้องระบุคำนำหน้า)"
      placeholder="ตัวอย่าง ทัศนีย์ ผดุงขวัญ"
      class="mt-4"
    />

    <!-- number account promptpay -->
    <c-field
      id="fullName"
      :value="promptpay.numberPromptpay"
      :disabled="!promptpay.active"
      @onChange="handleInput('numberPromptpay', $event)"
      label="เลขที่บัญชีพร้อมเพย์ (เบอร์โทรศัพท์ หรือ เลขประจำตัวประชาชนที่ผูกกับพร้อมเพย์)"
      placeholder="กรุณากรอกเบอร์โทรศัพท์ หรือ เลขประจำตัวประชาชนที่ผูกกับพร้อมเพย์"
    />

    <div class="text-right mt-6">
      <c-button color="primary" rounded="rounded-full" @click="submitSave">
        บันทึก
      </c-button>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

import CSwitch from '../Switch/CSwitch.vue'
import CField from '../FieldInput/CField.vue'
import CButton from '../Button/Cbutton.vue'

export default {
  components: {
    CSwitch,
    CField,
    CButton
  },
  setup () {
    const store = useStore()

    const promptpay = computed(() => store.state.signupForm.promptpay)

    const handleInput = (stateName, val) => {
      store.commit('signupForm/setPromptpay', { stateName, val })
    }

    const submitSave = async () => {
      try {
        store.commit('appState/TOGGLE_IS_LOADING')
        await store.dispatch('signupForm/save')
        store.dispatch('appState/showToast', {
          message: 'บันทึกแล้ว',
          delay: 1000
        })
      } catch (error) {
        console.error(error)
      } finally {
        store.commit('appState/TOGGLE_IS_LOADING')
      }
    }

    return {
      promptpay,
      handleInput,
      submitSave
    }
  }
}
</script>

<style>

</style>