<template>
  <div>
    <!-- date field -->
    <c-date
      :value="data.date"
      id="date"
      label="วันที่"
      formatDisplayInput="ddd, DD MMMM YYYY"
      @onChange="handleInput('date', $event)"
    />

    <!-- time field -->
    <c-time
      id="time"
      :value="data.time"
      label="ช่วงเวลา"
      @onChange="handleInput('time', $event)"
    />

    <!-- google map -->
    <c-field-google-map
      :location="data.location"
      id="fieldGoogleMap"
      placeholder="ระบุพิกัด"
      @saveMap="handleInput('location', { ...$event, active: true })"
      @onChangeSwitch="handleInputLocation('active', $event)"
    />

    <!-- name location field -->
    <c-field
      :value="data.location.name"
      id="nameLocation"
      label="ชื่อสถานที่"
      placeholder="ตัวอย่างโรงแรม Mandarin Oriental"
      type="text"
      @onChange="handleInputLocation('name', $event)"
    />

    <!-- address field -->
    <c-textarea
      :value="data.location.address"
      id="address"
      label="ที่อยู่"
      rows="5"
      placeholder="Text Field"
      @onChange="handleInputLocation('address', $event)"
    />

    <!-- room field -->
    <c-field
      :value="data.room"
      id="room"
      label="ห้อง"
      placeholder="ตัวอย่าง ห้อง ​Ballroom"
      type="text"
      @onChange="handleInput('room', $event)"
    />

    <!-- floor field -->
    <c-field
      :value="data.floor"
      id="floor"
      label="ชั้น"
      placeholder="ตัวอย่าง 8"
      type="text"
      @onChange="handleInput('floor', $event)"
    />

    <!-- theme field -->
    <c-field
      :value="data.theme"
      id="theme"
      label="ธีมงาน"
      placeholder="ตัวอย่าง ชุดไทย"
      type="text"
      @onChange="handleInput('theme', $event)"
    />

    <!-- food field -->
    <div class="mb-4">
      <label class="block text-16 font-normal text-mytheme-grayscale900 mb-2">
        อาหาร
      </label>
      <MultiSelect
        v-model="dataRef.foods"
        mode="tags"
        :clearOnSearch="true"
        :closeOnSelect="false"
        :searchable="true"
        :createTag="true"
        :options="[
          { value: 'โต๊ะจีน', label: 'โต๊ะจีน' },
          { value: 'บุฟเฟ่ต์', label: 'บุฟเฟ่ต์' },
          { value: 'ค็อกเทล', label: 'ค็อกเทล' }
        ]"
        placeholder="อาหาร"
        noResultsText="ไม่พบข้อมูล"
        style="border-radius: 8px; box-shadow: unset;"
        class="border w-full py-2 px-1 text-gray-700 leading-tight"
      >
        <template v-slot:singlelabel="{ value }">
          <div class="multiselect-single-label">
            {{ value }}
          </div>
        </template>
      </MultiSelect>
    </div>

    <!-- remark optional Field -->
    <c-field
      :value="data.remark"
      id="remark"
      label="หมายเหตุ (Optional)"
      placeholder="ตัวอย่าง จัด Outdoor ควรพกร่มมาด้วย"
      type="text"
      @onChange="handleInput('remark', $event)"
    />
  </div>
</template>

<script>
import { ref, onBeforeMount } from 'vue'

import CField from '../FieldInput/CField.vue'
import CTextarea from '../FieldInput/CTextarea.vue'
import CDate from '../FieldInput/CDatePicker.vue'
import CFieldGoogleMap from '../FieldInput/CFieldGoogleMap.vue'
import CTime from '../FieldInput/CTime.vue'
import MultiSelect from '@vueform/multiselect'

import '@vueform/multiselect/themes/default.css'

export default {
  props: {
    data: {
      type: Object
    }
  },
  components: {
    CField,
    CTextarea,
    CDate,
    CFieldGoogleMap,
    CTime,
    MultiSelect
  },
  setup (props, { emit }) {
    const dataRef = ref({})

    onBeforeMount(() => {
      dataRef.value = props.data
    })

    const handleInput = (key, val) => {
      dataRef.value[key] = val
      emit('onChange', dataRef.value)
    }

    const handleInputLocation = (key, val) => {
      dataRef.value.location[key] = val
      emit('onChange', dataRef.value)
      console.log(dataRef.value)
    }

    return {
      dataRef,
      handleInput,
      handleInputLocation
    }
  }
}
</script>

<style>
span.multiselect-tag {
  background-color: #0856cb;
}
</style>