<template>
  <div
    v-if="isActive"
    class="mt-8"
    :class="isActive ? 'fade' : 'opacity-0'"
  >
    <slot></slot>
  </div>
</template>

<script>
import { ref, inject, onBeforeMount, watch } from 'vue'

export default {
  props: ['title', 'val', 'count'],
  setup (props) {
    let index = ref(0)
    let isActive = ref(false)

    let tabs = inject('TabsProvider')

    watch(() => tabs.selectedIndex, () => {
      isActive.value = index.value === tabs.selectedIndex
    })

    watch(() => props.count, () => {
      tabs.stateChange = new Date().valueOf()
    })

    onBeforeMount(() => {
      index.value = tabs.count
      tabs.count++
      isActive.value = index.value === tabs.selectedIndex
    })

    return { index, isActive }
  }
}
</script>

<style>

</style>

