<template>
  <div class="mb-4">
    <label
      :for="id"
      class="block text-16 font-normal text-mytheme-grayscale900 mb-2"
    >
      {{ label }}
    </label>
    <input
      :value="value"
      @input="onInput"
      :id="id"
      type="time"
      class="shadow border rounded-lg w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
    >
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
    },
    id: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: 'label'
    }
  },
  setup (props, { emit }) {
    const onInput = (event) => {
      emit('onChange', event.target.value)
    }

    return {
      onInput
    }
  }
}
</script>

<style>

</style>