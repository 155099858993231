<template>
  <div>
    <!-- modal alert -->
    <modal
      :isShow="isShow"
      title="แจ้งเตือน"
      @close="closeModal"
    >
      <p class="text-20 text-mytheme-blue text-center py-20">
        {{ message }}
      </p>
    </modal>

    <!-- draggable -->
    <draggable
      :list="itemsGroup"
      @change="swapDraggable"
      class="w-full mt-2"
    >
      <form
        v-for="(item, index) in itemsGroup"
        :key="index"
        class="w-full flex items-center justify-between h-14 my-2 border rounded-lg px-7 py-4"
        :class="item.activeEdit ? 'border-mytheme-grayscale900' : 'border-mytheme-grayscale200'"
      >
        <div class="flex items-center w-full space-x-5 mr-4">
          <img class="cursor-all-scroll" src="../../assets/img/menu.png" width="20" height="20">
          <input
            type="text" v-model="item.text"
            :disabled="!item.activeEdit"
            class="w-full text-gray-700 bg-transparent leading-tight focus:outline-none focus:shadow-outline"
            :class="!item.activeEdit ? 'cursor-not-allowed' : ''"
          >
        </div>

        <!-- action -->
        <div v-if="item.activeEdit">
          <button
            type="submit"
            @click.prevent="submitDone(index)"
            class="text-mytheme-grayscale900 font-bold text-16"
          >
            Done
          </button>
        </div>

        <div v-else class="flex space-x-5">
          <button type="button" @click.prevent="submitToggleEdit(index)">
            <img src="../../assets/img/edit-white.png" width="22" height="22">
          </button>

          <button type="button" @click.prevent="submitDeleteGroup(index)">
            <img src="../../assets/img/bin.png" width="23" height="23">
          </button>
        </div>
      </form>
    </draggable>

    <div class="flex justify-between items-center mt-6">
      <button
        @click="submitAddGroup"
        class="w-28 h-10 border border-mytheme-grayscale200 rounded-full text-mytheme-blue hover:bg-gray-50"
      >
        <div class="flex items-center justify-center space-x-1">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-mytheme-blue" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
          </svg>
          <span>Add</span>
        </div>
      </button>

      <!-- save -->
      <!-- <c-button @onClick="submitSave" color="primary" rounded="rounded-full">
        Save
      </c-button> -->
    </div>
  </div>
</template>

<script>
import { VueDraggableNext } from 'vue-draggable-next'
import { ref, reactive, toRefs, onBeforeMount } from 'vue'

import CButton from '../Button/Cbutton.vue'
import Modal from '../Modal/Modal.vue'

export default {
  components: {
    draggable: VueDraggableNext,
    CButton,
    Modal
  },
  props: {
    items: {
      type: Array,
      require: true
    }
  },
  setup(props, { emit }) {
    let nameGroup = ref('')
    let itemsGroup = ref([])

    onBeforeMount(() => {
      init()
    })

    const init = () => {
      if(props.items.length <= 0) return itemsGroup.value.push({ text: '', activeEdit: true })
      itemsGroup.value = props.items.map(group => {
        return { text: group, activeEdit: false }
      })
    }

    const { isShow, message, openModal, closeModal } = useModalAlert()

    const onChange =  () => {
      let mItems = itemsGroup.value.map(item => item.text)
      emit('onChange', mItems)
    }

    const submitAddGroup = () => {
      itemsGroup.value.push({ text: '', activeEdit: true })
    }

    const submitToggleEdit = (indexAt) => {
      itemsGroup.value[indexAt].activeEdit = !itemsGroup.value[indexAt].activeEdit
    }

    const submitDeleteGroup = (indexAt) => {
      itemsGroup.value.splice(indexAt, 1)
      onChange()
      let mItems = itemsGroup.value.map(item => item.text)
      emit('save', mItems)
    }

    const submitDone = (indexAt) => {
      if(!itemsGroup.value[indexAt].text) return openModal('กรุณากรอกข้อมูลก่อนยืนยัน(Done) ครับ')
      submitToggleEdit(indexAt)
      onChange()
      let mItems = itemsGroup.value.map(item => item.text)
      emit('save', mItems)
    }

    const submitSave = () => {
      let filtered = itemsGroup.value.filter(item => item.activeEdit == true)
      if(filtered.length >= 1) return openModal('กรุณายืนยัน(Done) ก่อนทำการ Save ครับ')
      let mItems = itemsGroup.value.map(item => item.text)
      emit('save', mItems)
    }

    const swapDraggable = () => {
      onChange()
      let mItems = itemsGroup.value.map(item => item.text)
      emit('save', mItems)
    }

    return {
      nameGroup,
      itemsGroup,
      submitDone,
      submitAddGroup,
      submitToggleEdit,
      submitDeleteGroup,
      swapDraggable,
      submitSave,
      // modal alert
      isShow,
      message,
      closeModal,
    }
  }
}

const useModalAlert = () => {
  let isModalAlert = reactive({
    isShow: false,
    message: ''
  })

  const openModal = (message) => {
    isModalAlert.isShow = true
    isModalAlert.message = message
  }

  const closeModal = () => {
    isModalAlert.isShow = false
  }

  return {
    ...toRefs(isModalAlert),
    openModal,
    closeModal
  }
}
</script>