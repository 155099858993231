<template>
  <GMapAutocomplete
    class="shadow border rounded-lg w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
    placeholder="ค้นหา"
    @place_changed="handleChangeLocation"
  >
  </GMapAutocomplete>
</template>

<script>
export default {
  setup (props, { emit }) {
    const handleChangeLocation = (val) => {
      const { lat, lng } = val.geometry.location
      const { formatted_address, name, url } = val
      const coordinates = { lat: lat(), lng: lng() }
      emit('placeChange', { coordinates, name, formatted_address, url })
    }

    return {
      handleChangeLocation
    }
  }
}
</script>

<style>
.pac-container {
  z-index: 1060;
}
</style>