<template>
  <div class="pb-6">
    <!-- modal uplaod -->
    <modal-uplaod
      :isShow="modalUpload.isShow"
      @close="modalUpload.isShow = false"
      @onChange="handleUploadAndSaveImage"
    />

    <c-field
      id="groomName"
      :value="signupFormState.groomName"
      @onChange="handleStateInput('groomName', $event)"
      label="ชื่อเจ้าบ่าว"
      type="text"
      placeholder="ตัวอย่าง Jhon"
    />

    <c-field
      id="brideName"
      :value="signupFormState.brideName"
      @onChange="handleStateInput('brideName', $event)"
      label="ชื่อเจ้าสาว"
      type="text"
      placeholder="ตัวอย่าง Anna"
    />

    <div class="text-right mt-6">
      <c-button @onClick="submitSave" color="primary" rounded="rounded-full">
        บันทึก
      </c-button>
    </div>

    <hr class="my-8">

    <!-- upload welcome list -->
    <div v-for="(image, index) in signupFormState.welcomePageImages" :key="index">
      <div class="flex items-start">
        <!-- number -->
        <div class="w-2/12">
          <p class="text-16 text-mytheme-grayscale900 font-bold ml-4 mt-6">{{ index + 1 }}</p>
        </div>

        <!-- image -->
        <div class="w-7/12">
          <frame-image :image="image" :width="200" :height="300" :label="`รูปภาพ พรีเวดดิ้ง ${index + 1}`" />
        </div>

        <!-- upload -->
        <div class="w-3/12 text-right">
          <c-button @onClick="openModalUpload(index)" rounded="rounded-full" textColor="text-mytheme-blue">
            Upload
          </c-button>

          <c-button v-if="image" @onClick="submitRemove(index)" rounded="rounded-full" textColor="text-mytheme-red" class="mt-2">
            Remove
          </c-button>
        </div>
      </div>
      
      <hr v-if="index != signupFormState.welcomePageImages.length - 1" class="my-8">
    </div>
  </div>
</template>

<script>
import { computed, reactive } from 'vue'
import { useStore } from 'vuex'

import CField from '../FieldInput/CField.vue'
import CButton from '../Button/Cbutton.vue'
import CUpload from '../Button/CUpload.vue'
import FrameImage from '../FrameImage/Image.vue'
import ModalUplaod from '../Modal/ModalUpload.vue'

export default {
  components: {
    CField,
    CButton,
    CUpload,
    FrameImage,
    ModalUplaod
  },
  setup () {
    const store = useStore()

    let modalUpload = reactive({
      isShow: false,
      indexAt: null
    })

    const signupFormState = computed(() => store.state.signupForm)

    const openModalUpload = (indexAt) => {
      modalUpload.isShow = true
      modalUpload.indexAt = indexAt
    }

    const handleStateInput = (stateName, value) => {
      store.commit('signupForm/setState', { stateName, value })
    }

    const handleUploadAndSaveImage = async (file) => {
      try {
        store.commit('appState/TOGGLE_IS_LOADING')
        await store.dispatch('signupForm/uploadImageWelcomePage', { indexAt: modalUpload.indexAt, file })
        modalUpload.isShow = false
        store.dispatch('appState/showToast', {
          message: 'อัพโหลดรูปภาพสำเร็จแล้ว',
          delay: 1000 * 2
        })
      } catch (error) {
        console.error(error)
      } finally {
        store.commit('appState/TOGGLE_IS_LOADING')
      }
    }

    const submitSave = async () => {
      try {
        store.commit('appState/TOGGLE_IS_LOADING')
        await store.dispatch('signupForm/save')
        store.dispatch('appState/showToast', {
          message: 'บันทึกสำเร็จ',
          delay: 1000 * 1.5
        })
      } catch (error) {
        console.error(error)
      } finally {
        store.commit('appState/TOGGLE_IS_LOADING')
      }
    }

    const submitRemove = async (indexAt) => {
      try {
        store.commit('appState/TOGGLE_IS_LOADING')
        await store.dispatch('signupForm/removeImageWelcomePage', { indexAt })
      } catch (error) {
        console.error(error)
      } finally {
        store.commit('appState/TOGGLE_IS_LOADING')
      }
    }

    return {
      modalUpload,
      signupFormState,
      openModalUpload,
      handleStateInput,
      handleUploadAndSaveImage,
      submitSave,
      submitRemove
    }
  }
}
</script>

<style>

</style>