<template>
  <div class="rounded-t-xl shadow">

    <!-- title -->
    <div class="bg-mytheme-red rounded-t-xl text-center py-3">
      <p class="text-16 text-white font-semibold">{{ title }}</p>
    </div>

    <!-- detail body -->
    <div class="bg-mytheme-grayscale50 p-6">
      <!-- date -->
      <div v-if="data.date" class="flex items-center space-x-4">
        <div class="w-1/12">
          <img src="../../assets/img/icons/calendar.png" width="16" height="16" class="mt-1">
        </div>
        <div>
          <p class="text-16 text-mytheme-grayscale900 font-normal">
            {{ displayDate }}
          </p>
        </div>
      </div>

      <!-- time -->
      <div v-if="data.time" class="flex items-center space-x-4 mt-4">
        <div class="w-1/12">
          <img src="../../assets/img/icons/time.png" width="16" height="16" class="mt-1">
        </div>
        <div>
          <p class="text-16 text-mytheme-grayscale900 font-normal">
            เวลา​ {{ data.time }} น.
          </p>
        </div>
      </div>

      <!-- location -->
      <div v-if="data.time" class="flex items-start space-x-4 mt-4">
        <div class="w-1/12">
          <img src="../../assets/img/icons/location.png" width="16" height="16" class="mt-1">
        </div>
        <div class="w-full flex flex-col items-start">
          <p class="text-16 text-mytheme-grayscale900 font-normal text-left">
            {{ data.location.name }}
          </p>
          <p v-if="data.room" class="text-16 text-mytheme-grayscale900 font-normal">
            ห้อง {{ data.room }}<span v-if="data.floor">, ชั้น {{ data.floor }}</span>
          </p>
          <p class="text-14 text-left text-mytheme-grayscale600 font-normal mt-2">
            {{ data.location.address }}
          </p>
          <div v-if="data.location.active && data.location.coordinates" class="flex items-center space-x-2 cursor-pointer mt-2">
            <img src="../../assets/img/icons/map.png" width="16" height="16">
            <p class="text-mytheme-blue text-16 font-semibold">ดู Google Map</p>
          </div>
        </div>
      </div>

      <!-- theme -->
      <div v-if="data.theme" class="flex items-center space-x-4 mt-4">
        <div class="w-1/12">
          <img src="../../assets/img/icons/clothes.png" width="16" height="16" class="mt-1">
        </div>
        <div>
          <p class="text-16 text-mytheme-grayscale900 font-normal">
            {{ data.theme }}
          </p>
        </div>
      </div>

      <!-- food -->
      <div v-if="data.foods" class="flex items-center space-x-4 mt-4">
        <div class="w-1/12">
          <img src="../../assets/img/icons/shamp.png" width="16" height="16" class="mt-1">
        </div>
        <div>
          <p class="text-16 text-mytheme-grayscale900 font-normal">
            <span v-for="(food, index) in data.foods" :key="index">
              {{ food }}, 
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { computed } from 'vue'

export default {
  props: {
    title: {
      type: String,
      default: 'title'
    },
    data: {
      type: Object
    }
  },
  setup (props, { emit }) {

    const displayDate = computed(() => moment(props.data.date).locale('th').add(543, 'years').format('ddd, DD MMMM YYYY'))

    return {
      displayDate
    }
  }
}
</script>

<style>

</style>