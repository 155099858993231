<template>
  <div>
    <ul :class="tabClass" class="tabs flex">
      <li
        v-for='(tab, index) in tabs'
        :key="index"
        @click="selectTab(index, tab.props.val)"
        :class="index == selectedIndex ? 'border-b-2 border-mytheme-blue text-mytheme-blue' : 'text-mytheme-grayscale900 border-b border-mytheme-grayscale200'"
        class="cursor-pointer p-4 flex-1 text-center"
      >
        {{ tab.props.title }}
        <span v-if="tab.props.count != null" class="text-mytheme-blue text-12 bg-mytheme-offwhite rounded-3xl px-4 py-1 ml-1">
          {{ tab.props.count }}
        </span>
      </li>
    </ul>
    <slot></slot>
  </div>
</template>

<script>
import { reactive, onBeforeMount, onMounted, toRefs, provide, watch } from 'vue'

export default {
  props: ['tabClass', 'defaultTabAt'],
  emits: ['selectTab'],
  setup (props, { slots, emit }) {
    let state = reactive({
      selectedIndex: 0,
      tabs: [],
      count: 0,
      stateChange: new Date().valueOf()
    })

    provide('TabsProvider', state)

    watch(() => state.stateChange, () => {
      let tmpChild = []
      slots.default().forEach((child) => {
        tmpChild.push(child)
      })
      state.tabs = tmpChild
    })

    onBeforeMount(() => {
      if(slots.default) {
        slots.default().forEach((child) => {
          state.tabs.push(child)
        })
      }
    })

    onMounted(() => {
      selectTab(props.defaultTabAt || 0)
    })

    const selectTab = (indexAt, val) => {
      state.selectedIndex = indexAt
      if(!val) return
      emit('selectTab', { val, indexAt })
    }

    return { ...toRefs(state), selectTab }
  }
}
</script>