<template>
  <div v-if="isShow" class="flex flex-col justify-start items-center">
    <!-- card -->
    <div style="width: 700px; height: 693px;" class="fixed bg-white top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 p-4 rounded-2xl overflow-scroll">
      <!-- header -->
      <div class="flex justify-between items-center">
        <p class="text-20 text-mytheme-grayscale900 font-bold">ค้นหาที่ตั้ง</p>

        <div @click="closeModal" class="cursor-pointer">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </div>
      </div>

      <!-- body -->
      <div class="mt-3 text-center">
        <auto-complete-google-map
          @placeChange="handleAutoComplete"
          style="width: 95%;"
        />

        <div class="absolute left-0 mt-4 overflow-hidden">
          <google-map
            v-if="markerData"
            height="500"
            width="700"
            :marker="markerData"
            @dragMarker="handleDragMarker"
          />
          <!-- skeleton -->
          <div
            v-else
            class="animate-pulse shadow rounded-sm flex flex-col justify-center items-center"
            style="height: 500px; width: 700px;"
          >
            <div class="bg-blue-400 text-white px-10 py-3 rounded">MAP LOADING...</div>
          </div>
        </div>

        <div class="flex justify-between items-center absolute bottom-4 w-full left-0 px-10">
          <p class="text-mytheme-grayscale900 text-16 font-normal">
            ใช้ตำแหน่งปัจจุบัน
          </p>

          <c-button 
            color="dark"
            @onClick="submitSave"
          >
            ใช้ที่อยู่นี่
          </c-button>
        </div>
      </div>
    </div>

    <!-- overlay -->
    <div @click="closeModal" class="overlay" :class="isShow ? 'block' : 'hidden'"></div>
  </div>
</template>

<script>
import { ref, onBeforeMount } from 'vue'

import AutoCompleteGoogleMap from '../GoogleMap/AutoComplete.vue'
import GoogleMap from '../GoogleMap/GoogleMap.vue'
import CButton from '../Button/Cbutton.vue'

export default {
  components: {
    AutoCompleteGoogleMap,
    GoogleMap,
    CButton
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    location: {
      type: Object
    },
  },
  setup (props, { emit }) {
    const closeModal = () => {
      emit('close')
    }

    const submitSave = () => {
      emit('save', locationData.value)
      closeModal()
    }

    const {
      locationData,
      markerData,
      handleAutoComplete,
      handleDragMarker
    } = useLocation(props.location)

    return {
      closeModal,
      submitSave,
      locationData,
      markerData,
      handleAutoComplete,
      handleDragMarker
    }
  }
}

const useLocation = (location) => {
  let locationData = ref()
  let markerData = ref()

  onBeforeMount(() => {
    const { coordinates } = location
    if (coordinates) {
      // set location & marker
      locationData.value = location
      markerData.value = { lat: coordinates.lat, lng: coordinates.lng }
    } else {
      // get location
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          const { latitude, longitude } = position.coords
          markerData.value = { lat: latitude, lng: longitude }
        })
      } else {
        alert('browser not support my location')
      }
    }
  })

  const handleAutoComplete = (location) => {
    const { coordinates, name, formatted_address, url } = location
    markerData.value = coordinates
    locationData.value = {
      address: formatted_address,
      name,
      coordinates,
      url
    }
  }

  const handleDragMarker = (coordinates) => {
    const { lat, lng } = coordinates
    markerData.value = coordinates
    locationData.value = {
      address: locationData.value && locationData.value.address,
      name: locationData.value && locationData.value.name,
      coordinates: {
        lat: coordinates.lat,
        lng: coordinates.lng,
      },
      url: `https://www.google.com/maps/search/?api=1&query=${lat}%2C${lng}`
    }
  }

  return {
    locationData,
    markerData,
    handleAutoComplete,
    handleDragMarker
  }
}
</script>

<style>
</style>