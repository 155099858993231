<template>
  <div class="flex">
    <!-- column manage -->
    <div class="flex-1 flex flex-col px-10 pt-8">
      <div class="w-full">
        <p class="text-24 font-semibold text-mytheme-grayscale900">ตั้งค่าฟอร์มลงทะเบียน</p>
      </div>

      <div class="w-full py-4">
        <!-- collapse image slide -->
        <collapse
          title="ภาพสไลด์ (พรีเวดดิ้ง)"
          :isActive="isToggleCollapse.imageSlide"
          @toggle="submitToggle('imageSlide')"
          class="w-full my-3"
        >
          <template #imageIcon>
            <img src="../assets/img/image-hold.png" width="22">
          </template>

          <template #content>
            <SlideImage />
          </template>
        </collapse>


        <!-- collapse detail wedding -->
        <collapse
          title="รายละเอียดงานแต่ง"
          :isActive="isToggleCollapse.detailWedding"
          @toggle="submitToggle('detailWedding')"
          class="w-full my-3"
        >
          <template #imageIcon>
            <img src="../assets/img/edit-white.png" width="22">
          </template>

          <template #content>
            <DetailWedding />
          </template>
        </collapse>


        <!-- collapse manage group groom -->
        <collapse
          title="จัดการกลุ่มแขกเจ้าบ่าว"
          :isActive="isToggleCollapse.groupGroom"
          @toggle="submitToggle('groupGroom')"
          class="w-full my-3"
        >
          <template #imageIcon>
            <img src="../assets/img/groom.png" width="25">
          </template>

          <template #content>
            <manage-group
              :items="groomGroups"
              @onChange="onChange('groom', $event)"
              @save="submitSaveGuestGroup"
            />
          </template>
        </collapse>

        <!-- collapse manage group bride -->
        <collapse
          title="จัดการกลุ่มแขกเจ้าสาว"
          :isActive="isToggleCollapse.groupBride"
          @toggle="submitToggle('groupBride')"
          class="w-full my-3"
        >
          <template #imageIcon>
            <img src="../assets/img/bride.png" width="25">
          </template>

          <template #content>
            <manage-group
              :items="brideGroups"
              @onChange="onChange('bride', $event)"
              @save="submitSaveGuestGroup"
            />
          </template>
        </collapse>


        <!-- collapse manage ซอง -->
        <collapse
          title="ซอง"
          :isActive="isToggleCollapse.envelope"
          @toggle="submitToggle('envelope')"
          class="w-full my-3"
        >
          <template #imageIcon>
            <img src="../assets/img/envelope.png" width="22">
          </template>

          <template #content>
            <Envelope />
          </template>
        </collapse>
      </div>
    </div>

    <!-- column previews -->
    <preview class="w-96">
      <template #content>
        <!-- slide preview -->
        <slide-image-preview
          v-if="isToggleCollapse.imageSlide"
          :groomName="slideImagePreview.groomName"
          :brideName="slideImagePreview.brideName"
          :images="slideImagePreview.images"
        />

        <!-- detail wedding preview -->
        <detail-wedding-preview
          v-if="isToggleCollapse.detailWedding"
          :groomName="detailWeddingPreview.groomName"
          :brideName="detailWeddingPreview.brideName"
          :eventInfo="detailWeddingPreview.eventInfo"
        />
      </template>
    </preview>
  </div>
</template>

<script>
import { reactive, computed } from 'vue'
import { useStore } from 'vuex'

import Collapse from '../components/Collapse.vue'
import SlideImage from '../components/SignupFormGuest/SlideImage.vue'
import DetailWedding from '../components/SignupFormGuest/DetailWedding.vue'
import ManageGroup from '../components/SignupFormGuest/ManageGroup.vue'
import Envelope from '../components/SignupFormGuest/Envelope.vue'

// component preview
import Preview from '../components/Previews/Preview.vue'
import SlideImagePreview from '../components/Previews/SignupForm/SlideImage.vue'
import DetailWeddingPreview from '../components/Previews/SignupForm/DetailWedding.vue'

export default {
  components: {
    Collapse,
    SlideImage,
    DetailWedding,
    ManageGroup,
    Envelope,
    Preview,
    SlideImagePreview,
    DetailWeddingPreview
  },
  setup () {
    const store = useStore()

    const { isToggleCollapse, submitToggle } = useCollapseGroup(store)
    const {
      groomGroups,
      brideGroups,
      onChange,
      submitSave: submitSaveGuestGroup
    } = useManageGuestGroup(store)
    const { slideImagePreview, detailWeddingPreview } = useDisplayPreview(store)

    return {
      isToggleCollapse,
      submitToggle,
      // manage guest group
      groomGroups,
      brideGroups,
      onChange,
      submitSaveGuestGroup,
      // display preview
      slideImagePreview,
      detailWeddingPreview
    }
  }
}

const useCollapseGroup = () => {
  let isToggleCollapse = reactive({
    imageSlide: false,
    detailWedding: false,
    groupGroom: false,
    groupBride: false,
    envelope: false
  })

  const submitToggle = (keyName) => {
    if(isToggleCollapse[keyName] == true) return isToggleCollapse[keyName] = false
    for(const key in isToggleCollapse) {
      key == keyName ? isToggleCollapse[key] = true : isToggleCollapse[key] = false
    }
  }

  return {
    isToggleCollapse,
    submitToggle
  }
}

const useManageGuestGroup = (store) => {
  let groomGroups = computed(() => store.state.signupForm.guestGroup.groom)
  let brideGroups = computed(() => store.state.signupForm.guestGroup.bride)

  const onChange = (groomOrBride, groups) => {
    store.commit('signupForm/setGuestGroup', { stateName: groomOrBride, groups })
  }

  const submitSave = async () => {
    try {
      store.commit('appState/TOGGLE_IS_LOADING')
      await store.dispatch('signupForm/save')
      store.dispatch('appState/showToast', {
        message: 'บันทึกสำเร็จ',
        delay: 1000 * 1.5
      })
    } catch (error) {
      console.error(error)
    } finally {
      store.commit('appState/TOGGLE_IS_LOADING')
    }
  }

  return {
    groomGroups,
    brideGroups,
    onChange,
    submitSave
  }
}

const useDisplayPreview = (store) => {
  const slideImagePreview = computed(() => {
    const { welcomePageImages, groomName, brideName } = store.state.signupForm
    return {
      images: welcomePageImages,
      groomName,
      brideName
    }
  })
  const detailWeddingPreview = computed(() => {
    const { eventInfo, groomName, brideName } = store.state.signupForm
    return {
      eventInfo,
      groomName,
      brideName
    }
  })

  return {
    slideImagePreview,
    detailWeddingPreview
  }
}
</script>

<style>

</style>