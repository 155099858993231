<template>
  <div class="bg-white mb-16">
    <!-- bar -->
    <div class="relative flex justify-center items-center p-4 border-b border-mytheme-grayscale300">
      <div class="absolute left-4 top-1/2 transform -translate-y-1/2 cursor-pointer">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-mytheme-blue" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
        </svg>
      </div>

      <p class="text-16 text-mytheme-grayscale900 font-semibold">รายละเอียด</p>
    </div>

    <!-- content -->
    <div class="p-7 text-center">
      <p class="text-mytheme-blue font-bold text-20" style="font-family: dmsans;">{{ groomName }} & {{ brideName }}</p>
      <div class="text-16 font-normal text-mytheme-grayscale900 mt-4">
        <p>มีความยินดีขอเรียนเชิญเพื่อเป็นเกียรติ</p>
        <p>เนื่องในพิธีมงคลสมรส</p>
      </div>

      <!-- card detail morning -->
      <card-detail-wedding
        v-if="eventInfo.mode == 'morning' || eventInfo.mode == 'morningAndEvening'"
        title="งานเช้า"
        :data="eventInfo.morning"
        class="mt-7"
      />

      <!-- card detaile evening -->
      <card-detail-wedding
        v-if="eventInfo.mode == 'evening' || eventInfo.mode == 'morningAndEvening'"
        title="งานเย็น"
        :data="eventInfo.evening"
        class="mt-7"
      />

      <div class="mt-7">
        <button class="px-6 py-2 shadow-sm text-16 font-normal border border-mytheme-grayscale300 rounded-full text-mytheme-blue flex items-center mx-auto">
          <img src="../../../assets/img/icons/gift.png" width="16" height="16" class="mr-3">
          ดูการ์ดเชิญ
        </button>
      </div>
    </div>

    <!-- footer action -->
      <div class="rounded-t-3xl shadow-sm bg-mytheme-offwhite px-2 py-10">
        <p class="text-16 font-normal text-center">ท่านสะดวกเข้าร่วมพิธีมงคลสมรสนี้หรือไม่?</p>

        <div class="flex justify-center items-center space-x-5 mt-6">
          <button class="w-40 px-2 py-3 shadow-sm text-16 font-normal border border-mytheme-grayscale300 rounded-full text-mytheme-blue">
            ไม่สะดวกเข้าร่วม
          </button>

          <button class="w-40 px-2 py-3 shadow-sm text-16 font-normal rounded-full bg-mytheme-blue text-white">
            เข้าร่วมงาน
          </button>
        </div>
      </div>
  </div>
</template>

<script>
import CardDetailWedding from '../../Card/DetailWedding.vue'

export default {
  components: {
    CardDetailWedding
  },
  props: {
    groomName: {
      type: String
    },
    brideName: {
      type: String
    },
    eventInfo: {
      type: Object
    }
  }
}
</script>

<style>

</style>