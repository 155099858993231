<template>
  <GMapMap
    :center="markerData"
    :zoom="18"
    map-type-id="terrain"
    :style="`width: ${width}px; height: ${height}px;`"
  >
    <GMapCluster>
      <GMapMarker
        :position="markerData"
        :clickable="true"
        :draggable="true"
        @dragend="handleDragMarker"
      />
    </GMapCluster>
  </GMapMap>
</template>

<script>
import { onBeforeMount, ref, watch } from 'vue'

export default {
  props: {
    marker: {
      type: Object
    },
    width: {
      type: String,
      default: '500'
    },
    height: {
      type: String,
      default: '500'
    }
  },
  setup (props, { emit }) {
    let markerData = ref()

    onBeforeMount(() => {
      if(props.marker) markerData.value = props.marker 
    })

    watch(() => props.marker, (myValue) => {
      markerData.value = myValue
    })

    const handleDragMarker = (val) => {
      const { lat, lng } = val.latLng
      const coordinates = { lat: lat(), lng: lng() }
      markerData.value = coordinates
      emit('dragMarker', coordinates)
    }

    return {
      markerData,
      handleDragMarker
    }
  }
}
</script>

<style>

</style>