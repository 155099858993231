<template>
  <div class="mb-4">
    <label
      :for="id"
      class="block text-16 font-normal text-mytheme-grayscale900 mb-2"
    >
      {{ label }}
    </label>
    <v-date-picker
      v-model="dateData"
      :available-dates='{ start: new Date(), end: null }'
      :masks="{ input: 'DD/MM/YYYY' }"
      mode="date"
      locale="th"
    >
      <template v-slot="{ togglePopover }">
        <input
          :id="id"
          class="shadow border rounded-lg w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          :value="formatDate(dateData, 'ddd DD/MM/YYYY')"
          @click.stop="
            togglePopover({
              placement: 'bottom',
              showDelay: 0,
              hideDelay: 0,
            })
          "
        />
      </template>
    </v-date-picker>
  </div>
</template>

<script>
import moment from 'moment'
import { onBeforeMount, ref, watch } from 'vue'

export default {
  props: {
    value: {
      type: String
    },
    id: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: 'label'
    },
    placeholder: {
      type: String,
      default: 'Placeholder'
    },
    type: {
      type: String,
      default: 'text'
    },
    formatDisplayInput: {
      type: String,
      default: 'DD/MM/YYYY'
    }
  },
  setup (props, { emit }) {
    const formatDate = (timestamp) => {
      return moment(timestamp).locale('th').add(543, 'years').format(props.formatDisplayInput)
    }
    const dateData = ref(null)
    const isLoaded = ref(false)

    watch(dateData, (myVal, prevVal) => {
      if(isLoaded.value) emit('onChange', new Date(myVal).toISOString())
    })

    onBeforeMount(() => {
      dateData.value = new Date(props.value)
      isLoaded.value = true
    })

    return {
      formatDate,
      dateData
    }
  }
}
</script>

<style>

</style>